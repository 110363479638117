import React, { useState } from 'react';
import JSZip from 'jszip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileImport } from '@fortawesome/free-solid-svg-icons';

interface FileUploaderProps {
  onFileProcessed: (data: { followers: any; followings: any }, username: string, date: string) => void;
  defaultUsername: string;
  defaultDate: string;
}

const FileUploader: React.FC<FileUploaderProps> = ({ onFileProcessed, defaultUsername, defaultDate }) => {
  const [loading, setLoading] = useState(false);

  const extractInfoFromFilename = (filename: string): { username: string; date: string } => {
    const regex = /instagram-([^-]+)-(\d{4}-\d{2}-\d{2}|\d{2}-\d{2}-\d{4})-/;
    const match = filename.match(regex);
    if (match) {
      const username = match[1];
      const date = match[2];
      return { username, date };
    }
    return { username: defaultUsername, date: defaultDate };
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      processFile(files[0]);
    }
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    if (files && files.length > 0) {
      processFile(files[0]);
    }
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const processFile = (file: File) => {
    setLoading(true);
    const activeExtractInfoFromFilename = true;

    const { username, date } = extractInfoFromFilename(file.name);
console.log('username :>> ', username);
console.log('date :>> ', date);


    const zip = new JSZip();
    zip.loadAsync(file)
      .then((zip) => {
        return Promise.all([
          zip.file('connections/followers_and_following/followers_1.json')?.async('text'),
          zip.file('connections/followers_and_following/following.json')?.async('text')
        ]);
      })
      .then(([followersData, followingData]) => {
        if (followersData && followingData) {
          const followers = JSON.parse(followersData);
          const following = JSON.parse(followingData);

          if(activeExtractInfoFromFilename)
            onFileProcessed({ followers, followings: following }, username, date);
          else
            onFileProcessed({ followers, followings: following }, "", "");

        } else {
          alert('Les fichiers nécessaires ne sont pas présents dans le zip.');
        }
      })
      .catch((error) => {
        console.error('Error processing the file:', error);
        alert('Erreur lors du traitement du fichier.');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div
      className="file-uploader border p-2"
      onDrop={handleDrop}
      onDragOver={handleDragOver}
    >
      <input
        type="file"
        accept=".zip"
        onChange={handleFileChange}
        style={{ display: 'none' }}
        id="file-input"
      />
      <label htmlFor="file-input">
        <div
          style={{
            padding: '30px',
            minHeight: '10vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            backgroundColor: '#f0f0f0',
            border: '2px dashed #ddd'
          }}
        >
          {loading ? (
            <div>Chargement...</div>
          ) : (
            <div>
              <FontAwesomeIcon icon={faFileImport} size='6x' color='#d8dadd' />
            </div>
          )}
        </div>
      </label>
    </div>
  );
};

export default FileUploader;
