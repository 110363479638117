// App.tsx
import React, { useEffect, useState } from 'react';
import './App.css';
import './bootstrap-grid.css';
import { useTranslation } from 'react-i18next';
import { faArrowLeft, faDownload, faFileImport, faUsers, faTrash, faChartLine, faFloppyDisk, faClockRotateLeft, faDiamondTurnRight, faLock, faUnlock, faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FileUploader from './FileUploader';
import { AppTabs, PageName } from './enum';
import DownloadModal from './DownloadModal';
import { downloadCSV } from './csvUtils';
import LanguageSwitcher from './LanguageSwitcher';
import logoImage from './images/igplus_48.png';
import BackupList, { Backup, compareUsernameAndDate } from './BackupList';
import FollowersChart, { ChartData } from './FollowersChart';
import FollowersTimelineChart, { ChartDataTimeLine } from './FollowersTimelineChart';
import { format, interval, parseISO } from 'date-fns';

interface Follower {
  locked: any;
  href: string;
  value: string;
  timestamp: number;
}

interface ProcessedData {
  [PageName.FOLLOWERS]: Follower[];
  [PageName.FOLLOWINGS]: Follower[];
  [PageName.IDONTFOLLOWBACK]: Follower[];
  [PageName.DONTFOLLOWMEBACK]: Follower[];
  [PageName.LOSTFOLLOWER]?: Follower[];
  [PageName.NEWFOLLOWERS]?: Follower[];
  [PageName.NEWFOLLOWINGS]?: Follower[];
  [PageName.OLDFOLLOWING]?: Follower[];
}

function convertTimestampToYearDate(timestamp: number, locale: string) {
  const date = new Date(timestamp * 1000);
  //console.log(date, ' timestamp :>> ', timestamp);
  const yearDate = date.toLocaleDateString(locale, { year: 'numeric', month: '2-digit' });
  //console.log('yearDate :>> ', yearDate);
  return yearDate;
}

const NB_YEAR = 5
function getLastYearsDates() {
  const currentDate = new Date();
  const datesList = [];

  for (let i = 0; i < NB_YEAR; i++) {
    const year = currentDate.getFullYear() - i;
    for (let month = 12; month > 0; month--) {
      const date = new Date(year, month, 0); // Get the last day of the month
      const dateString = date.toLocaleDateString('en-US', { year: 'numeric', month: '2-digit' });
      datesList.push(dateString);
    }
  }

  return datesList;
}

function App() {
  const { t: translate } = useTranslation();
  const [fileData, setFileData] = useState<ProcessedData | null>(null);
  const [currentTab, setCurrentTab] = useState<AppTabs>(AppTabs.UPLOAD);
  const [detailPage, setDetailIsPage] = useState<PageName | null>(null);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [username, setUsername] = useState('');
  const [selectedDate, setSelectedDate] = useState(() => new Date().toISOString().split('T')[0]);
  const [backups, setBackups] = useState<{ username: string; date: string }[]>([]);
  const [previousExist, setPreviousExist] = useState<boolean>(false);
  const [zipData, setZipData] = useState<boolean>(false);
  const [ascendingOrder, setAcendingOrder] = useState<boolean | null>(null);

  const [elementsUpdated, setUpdatedElement] = useState<string[]>([]);
  const [chartData, setChartData] = useState<ChartData[]>([]);
  const [chartDataTimeline, setChartDataTimeline] = useState<ChartDataTimeLine[]>([]);

  const compareLocked = (f1: Follower, f2: Follower) => {
    const sens = ascendingOrder === null ? 0 : ascendingOrder ? 1 : -1;
    if (f1.locked === f2.locked) {
      return f1.timestamp > f2.timestamp ? sens : sens * -1;
    }
    return f1.locked ? sens : sens * -1;
  };

  const handleFileProcessed = (data: { followers: any; followings: any }, username: string, date: string) => {
    const followers = data.followers?.map((data: any) => ({
      href: data.string_list_data[0]?.href,
      value: data.string_list_data[0]?.value,
      timestamp: data.string_list_data[0]?.timestamp,
      locked: false
    }));

    const followings = data.followings?.relationships_following.map((data: any) => ({
      href: data.string_list_data[0]?.href,
      value: data.string_list_data[0]?.value,
      timestamp: data.string_list_data[0]?.timestamp,
      locked: false
    }));

    if (username) setUsername(username);
    if (date) setSelectedDate(date);
    processData({ followers, followings }, username, date);
    setZipData(true);
  };

  const saveData = (username: string, date: string, data: { followers: any; followings: any }) => {
    if (!username || !data) { alert(translate("userNotDefine")); return; }
    const existingData = JSON.parse(localStorage.getItem('backup_metadata') || '[]');

    if (elementsUpdated.length > 0) {
      localStorage.setItem(`data_${username}_${date}`, JSON.stringify(data));
      setUpdatedElement([]);
    } else if (!existingData.find((data: any) => data.username === username && data.date === date)) {
      const userBackups = existingData.filter((item: any) => item.username === username);
      if (userBackups.length >= 2) {
        const oldestBackup = userBackups.reduce((prev: any, curr: any) => (prev.date < curr.date ? prev : curr));
        localStorage.removeItem(`data_${username}_${oldestBackup.date}`);
        const updatedData = existingData.filter((item: any) => !(item.username === username && item.date === oldestBackup.date));
        localStorage.setItem('backup_metadata', JSON.stringify(updatedData));
      }

      localStorage.setItem(`data_${username}_${date}`, JSON.stringify(data));
      existingData.push({ username, date });
      localStorage.setItem('backup_metadata', JSON.stringify(existingData));
      loadBackups();

      setZipData(false);
    } else {
      alert(translate("fileAlreadyExistwithUserNameAndDate"));
    }
  };

  const loadData = (username: string, date: string) => {
    const data = localStorage.getItem(`data_${username}_${date}`);
    return data ? JSON.parse(data) : null;
  };

  const loadBackups = () => {
    const metadata = JSON.parse(localStorage.getItem('backup_metadata') || '[]');
    setBackups(metadata);
  };

  const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(e.target.value);
  };

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDate(e.target.value);
  };

  const handleSelectBackup = (username: string, date: string) => {
    const loadedData = loadData(username, date);
    if (loadedData) {
      setUsername(username);
      setSelectedDate(date);
      setDetailIsPage(null);
      processData(loadedData, username, date);
      setCurrentTab(AppTabs.USERS);
    }
  };

  const handleDeleteBackup = (username: string, date: string) => {
    localStorage.removeItem(`data_${username}_${date}`);
    const metadata = JSON.parse(localStorage.getItem('backup_metadata') || '[]');
    const updatedData = metadata.filter((item: any) => !(item.username === username && item.date === date));
    localStorage.setItem('backup_metadata', JSON.stringify(updatedData));
    loadBackups();
  };

  const setStatus = (indexUser: number, page: PageName, locked: boolean) => {
    const newData: ProcessedData = {
      [PageName.FOLLOWERS]: fileData ? fileData[PageName.FOLLOWERS] : [],
      [PageName.FOLLOWINGS]: fileData ? fileData[PageName.FOLLOWINGS] : [],
      [PageName.IDONTFOLLOWBACK]: fileData ? fileData[PageName.IDONTFOLLOWBACK] : [],
      [PageName.DONTFOLLOWMEBACK]: fileData ? fileData[PageName.DONTFOLLOWMEBACK] : [],
      ...fileData
    };

    if (newData[page]) {
      newData[page]![indexUser].locked = locked;
      if (!elementsUpdated.find((el: any) => el === (page + indexUser))) {
        setUpdatedElement([...elementsUpdated, (page + indexUser)]);
      } else {
        setUpdatedElement(elementsUpdated.filter((el: any) => el != ((page + indexUser))));
      }
      setFileData(newData);
    }
  };

  // Fonction pour convertir "MM/YYYY" en timestamp du premier jour du mois suivant
const convertToNextMonthTimestamp = (dateStr:string) => {
  const [month, year] = dateStr.split('/').map(Number);
  const nextMonth = month === 12 ? 1 : month + 1;
  const nextYear = month === 12 ? year + 1 : year;
  const date = new Date(nextYear, nextMonth - 1, 1); // Mois est 0-indexé en JavaScript
  return Math.floor(date.getTime() / 1000); // Convertir en secondes
}
// Convert "MM/YYYY" to timestamp of the first day of the current month and the next month
const convertToTimestampRange = (dateStr:string) => {
  const [month, year] = dateStr.split('/').map(Number);
  const nextMonth = month === 12 ? 1 : month + 1;
  const nextYear = month === 12 ? year + 1 : year;
  const currentMonthDate = new Date(year, month - 1, 1); // Month is 0-indexed in JavaScript
  const nextMonthDate = new Date(nextYear, nextMonth - 1, 1); // Month is 0-indexed in JavaScript
  return {
    currentMonthTimestamp: Math.floor(currentMonthDate.getTime() / 1000),
    nextMonthTimestamp: Math.floor(nextMonthDate.getTime() / 1000),
  };
}

// Filter followers by date range
const  filterFollowersByDateRange = (followers: Follower[], currentDateStr: string) => {
  const { currentMonthTimestamp, nextMonthTimestamp } = convertToTimestampRange(currentDateStr);
  return followers.filter(follower => follower.timestamp >= currentMonthTimestamp && follower.timestamp < nextMonthTimestamp);
}
// Fonction pour filtrer les followers
const filterFollowersByDate = (followers: Follower[], currentDateStr: string) => {
  const nextMonthTimestamp = convertToNextMonthTimestamp(currentDateStr);
  return followers.filter((follower:Follower) => follower.timestamp < nextMonthTimestamp);
}

  const allChartData = (backs: Backup[]) => {
    
    let newCharDate : ChartData[] = [];
    const copyBackups = [...backs];
    const MAX_USE = copyBackups.length-1;

    console.log(backs,' copyBackups :>> ', copyBackups, " : ", backups , " ", MAX_USE);

    //console.log('"enter" :>> ', copyBackups.sort(compareUsernameAndDate).splice(0,3));  
    copyBackups.sort(compareUsernameAndDate).splice(0,5).forEach((data: any) => {
      console.log("enter");
      const loadedData = loadData(data.username, data.date);
      const newData = mapNewData(loadedData, username, data.date);
      // Prepare chart data
      const chartDataPoint: ChartData = {
        date: data.date,
        followers: loadedData.followers.length,
        lostFollowers: newData[PageName.LOSTFOLLOWER]?.length || -1,
        newFollowers: newData[PageName.NEWFOLLOWERS]?.length || -1,
      };
      //setChartData(prevData => [...prevData, chartDataPoint]);
      newCharDate = [...newCharDate, chartDataPoint];
    })
    
    console.log(backs,' copyBackups :>> ', copyBackups, " : ", backups , " ", MAX_USE);
    
    setChartData(newCharDate.reverse());

    const data = backs[0];
    //console.log('data :>> ', copyBackups);
    if(data) {

    
    const loadedData = loadData(data.username, data.date);
    console.log('loadedData :>> ', loadedData);
    const newData = mapNewData(loadedData, username, data.date);
    console.log('newData :>> ', newData);
    let newCharDateTimeLine : any[] = [];


    const last5YearsDates = getLastYearsDates();
//console.log('last5YearsDates :>> ', last5YearsDates.reverse());
    last5YearsDates.forEach((date: string) => {
      // console.log('newCharDateTimeLine :>> ', newCharDateTimeLine);
      // Prepare chart data
      // Exemple d'utilisation
// const followers = [
//   { href: "https://example.com/1", value: "Follower 1", timestamp: 1714101722 },
//   { href: "https://example.com/2", value: "Follower 2", timestamp: 1614101722 },
//   { href: "https://example.com/3", value: "Follower 3", timestamp: 1514101722 },
// ];

// const currentDateStr = "12/2020";
const filteredFollowers = filterFollowersByDate(loadedData.followers, date);
const filteredFollings = filterFollowersByDate(loadedData.followings, date);
const filteredFollowersMonth = filterFollowersByDateRange(loadedData.followers, date);

    // Generate info for each filtered follower
    const userInfo = filteredFollowersMonth.map((fl) => {
      const isFollower = newData[PageName.DONTFOLLOWMEBACK].find((fol) => fol.value === fl.value) ? 'Yes' : 'No';
      return `${fl.value} ${convertUnixTimestampToString(fl.timestamp)} ${translate('followers')}} : ${isFollower} `;
    });

// console.log(filteredFollowers);
//       const somme = loadedData.followers.map((fl: Follower) => { return { username: fl.value, date: convertTimestampToYearDate(fl.timestamp, 'en-US') } })
//         .filter((el: any) => {  
//           const d1 = el.date;
//           const d2 = date;
//           console.log(" follower ", el.username, " ", (d2 < d1) ? "true" : "false", 'd1 :>> ', d1, " d2 ", d2,);
//           // debugger
//           return (d2 < d1);
//         }
//         ).length;
      //console.log(date, 'somme :>> ', filteredFollowers);
      
      const chartDataPoint: ChartDataTimeLine = {
        date,
        followers: filteredFollowers.length,
        followings: filteredFollings.length,
        userInfos: userInfo.reduce((prev: any, curr: any) => (prev  + curr + ' ; \n '), '')
      };

      newCharDateTimeLine = [...newCharDateTimeLine, chartDataPoint];
    });
    //console.log('newCharDateTimeLine :>> ', newCharDateTimeLine);
    //setChartDataTimeline(prevData => [...prevData, chartDataPoint]);
    setChartDataTimeline(newCharDateTimeLine.reverse());

    //     'en-US');
    // const frYearDate = convertTimestampToYearDate(timestamp, 'fr-FR');

    // newData[PageName.FOLLOWERS].reverse().forEach((follower: Follower) => {
    //  // console.log('newCharDateTimeLine :>> ', newCharDateTimeLine);
    //   // Prepare chart data
    //   const somme = newCharDateTimeLine.reduce( (accumulator, currentValue) => accumulator + loadedData.followers.filter((fl:Follower) =>
    //     {
    //       const d1 = convertUnixTimestampToString(fl.timestamp);
    //       const d2 = convertUnixTimestampToString(follower.timestamp);
    //       console.log(" follower ", follower , " ",d1 <= d2 ? "true" : "false", 'd1 :>> ', d1, " d2 ", d2, );
    //      // debugger
    //       return d1 <= d2;
    //     } 
    //   ).length, 0);

    //   const chartDataPoint: ChartDataTimeLine = {
    //     date: convertTimestampToYearDate(follower.timestamp, 'en-US'),
    //       followers: newCharDateTimeLine.reduce( (accumulator, currentValue) => accumulator + 1, 0),
    //       userName: follower.value,
    //       follower: newData[PageName.DONTFOLLOWMEBACK].find((fl:Follower) => fl.value === follower.value) ? "No" : "Yes"
    //     };
    //     console.log(follower.value, ' object :>> ', somme);
    //     newCharDateTimeLine = [...newCharDateTimeLine, chartDataPoint];

    //   });
    //   //setChartDataTimeline(prevData => [...prevData, chartDataPoint]);
    //   setChartDataTimeline(newCharDateTimeLine);

    //   console.log('newCharDateTimeLine :>> ', newCharDateTimeLine);


    // Prepare chart data
    // const dateMap: { [key: string]: number } = {};

    // loadedData.followers.reverse().forEach((follower: any) => {
    //   const dateKey = format(new Date(follower.timestamp * 1000), 'yyyy-MM');//(interval === 'year') ? 'yyyy' : 
    //   if (dateMap[dateKey]) {
    //     dateMap[dateKey] += 1;
    //   } else {
    //     dateMap[dateKey] = 1;
    //   }
    // });

    // const chartData: ChartDataTimeLine[] = Object.keys(dateMap).map(dateKey => ({
    //   date: dateKey,
    //   count: dateMap[dateKey],
    //   users: ['test','222']
    // }));

    // setChartDataTimeline(chartData);
  }

  }

  useEffect(() => {
    if (backups.length > 0)
      allChartData(backups);

  }, [backups])

  const processData = (data: { followers: any; followings: any }, username: string, date: string) => {
    setZipData(false);
    setFileData(mapNewData(data, username, date));
    setUpdatedElement([]);
  }
  const mapNewData = (data: { followers: any; followings: any }, username: string, date: string) => {
    const { followers, followings } = data;

    const followersSet = new Set(followers.map((follower: Follower) => follower.value));
    const followingsSet = new Set(followings.map((followings: Follower) => followings.value));

    const dontFollowMeBack = followings.filter((followings: any) => !followersSet.has(followings.value));
    const iDontFollowBack = followers.filter((follower: any) => !followingsSet.has(follower.value));

    const previousData = loadPreviousData(username, date);
    const lostFollower = previousData.followers.filter((follower: any) => !followersSet.has(follower.value));
    const newFollowers = followers.filter((follower: any) => !previousData.followersSet.has(follower.value));
    const newFollowings = previousData.followings.length > 0 && followings.filter((followings: any) => !previousData.followingsSet.has(followings.value));
    const oldFollowings = previousData.followings.filter((followings: any) => !followingsSet.has(followings.value));

    const newData: ProcessedData = {
      [PageName.FOLLOWERS]: followers,
      [PageName.FOLLOWINGS]: followings,
      [PageName.IDONTFOLLOWBACK]: iDontFollowBack,
      [PageName.DONTFOLLOWMEBACK]: dontFollowMeBack,
    };
    if (previousData.followers.length > 0 && previousData.followings.length > 0) {
      newData[PageName.LOSTFOLLOWER] = lostFollower;
      newData[PageName.NEWFOLLOWERS] = newFollowers;
      newData[PageName.NEWFOLLOWINGS] = newFollowings;
      newData[PageName.OLDFOLLOWING] = oldFollowings;
    }

    return newData;
  };

  const compareDateAndUsername = (a: Backup, b: Backup) => {
    if (a.date === b.date) {
      return b.username.localeCompare(a.username);
    }
    return b.date.localeCompare(a.date);
  };
  // const comparetimestampAndValue = (a: Follower, b: Follower) => {
  //   if (a.timestamp === b.timestamp) {
  //     return b.value.localeCompare(a.value);
  //   }
  //   return b.timestamp < a.timestamp;
  // };

  const loadPreviousData = (username: string, currDate: string) => {
    const metadata = JSON.parse(localStorage.getItem('backup_metadata') || '[]');
    const previousBackup = metadata.sort(compareDateAndUsername).find((item: any) => item.date < currDate);

    if (previousBackup) {
      const previousData = loadData(previousBackup.username, previousBackup.date);
      const followersSet = new Set(previousData.followers.map((follower: any) => follower.value));
      const followingsSet = new Set(previousData.followings.map((followings: any) => followings.value));

      setPreviousExist(true);
      return {
        followers: previousData.followers,
        followings: previousData.followings,
        followersSet,
        followingsSet,
      };
    }

    setPreviousExist(false);
    return {
      followers: [],
      followings: [],
      followersSet: new Set(),
      followingsSet: new Set(),
    };
  };

  const convertUnixTimestampToString = (timestamp: number) => {
    const date = new Date(timestamp * 1000);
    return date.toLocaleString();
  };

  const handleButtonClick = (type: PageName) => {
    if (!fileData) return;
    setDetailIsPage(type);
  };

  const handleDownload = (selectedData: string[]) => {
    if (!fileData) return;

    selectedData.forEach((dataType) => {
      const data = fileData[PageName[dataType.toUpperCase() as keyof typeof PageName]];
      if (data) {
        downloadCSV(data, dataType);
      } else alert(translate('dataNotFound'));
    });
  };

  useEffect(() => {
    if (fileData) {
      setCurrentTab(AppTabs.USERS);
    }
  }, [fileData]);

  useEffect(() => {
    loadBackups();
  }, []);

  const userRender = (data: Follower, index: number, detailPage: PageName) => {
    return <div key={index + "user"} className="user-container p-2">
      <div className='d-flex justify-content-between'>
        <div className="user-item p-1">
          <div></div>
          <div>
            <div className=''>
              <a target="" href={data.href} rel="noopener noreferrer">{data.value}</a>
            </div>
            <div className='p-1'>{convertUnixTimestampToString(data.timestamp)}</div>
          </div>
          {data.locked ?
            <button className='commun-button btn-black border' onClick={() => setStatus(index, detailPage, false)}>
              <FontAwesomeIcon icon={faLock} />
            </button> :
            <button className='commun-button btn-gray border' onClick={() => setStatus(index, detailPage, true)}>
              <FontAwesomeIcon icon={faUnlock} />
            </button>
          }
        </div>
      </div>
    </div>
  };

  return (
    <div className="App">
      <div className="container-app">
        <header className="container-title">
          <div className='d-flex justify-content-between mt-2'>
            <div style={{ width: "20%" }} className='logo'>
              <img src={logoImage} alt="Logo" style={{ width: "30%", height: "auto" }} />
            </div>
            <div style={{ width: "60%", textAlign: 'center' }}><h1>{translate('appTitle')}</h1></div>
            <div style={{ width: "20%" }}>
              <LanguageSwitcher />
            </div>
          </div>
          <p>{translate('appDescription')}</p>
        </header>

        <main className='main mt-2'>
          <section className="col-md-6 col-sm-12 col-12">
            <div className="breadcrumbs">
              <button className={"commun-button tab " + (currentTab === AppTabs.UPLOAD ? "btn-black" : "btn-gray")} onClick={() => setCurrentTab(AppTabs.UPLOAD)} aria-label={translate('uploadData')}>
                <FontAwesomeIcon icon={faFileImport} />
              </button>
              <button
                className={"commun-button tab " + (currentTab === AppTabs.USERS ? "btn-black" : "btn-gray")}
                onClick={() => setCurrentTab(AppTabs.USERS)}
                aria-label={translate('viewUsers')}
              >
                <FontAwesomeIcon icon={faUsers} />
              </button>
              <button
                className={"commun-button tab " + (currentTab === AppTabs.HISTORY ? "btn-black" : "btn-gray")}
                onClick={() => setCurrentTab(AppTabs.HISTORY)}
                aria-label={translate('viewUsers')}
              >
                <FontAwesomeIcon icon={faClockRotateLeft} /> {backups.length}
              </button>
              <button
                className={"commun-button tab " + (currentTab === AppTabs.STATS ? "btn-black" : "btn-gray")}
                onClick={() => setCurrentTab(AppTabs.STATS)}
                aria-label={translate('viewUsers')}
              >
                <FontAwesomeIcon icon={faChartLine} />
              </button>
            </div>

            <div className="container-result border">
              <div className='container-result-option d-flex justify-content-between'>
                <div className='d-flex justify-content-center' style={{ width: "20%" }}>
                  {(detailPage && currentTab === AppTabs.USERS) && (
                    <button className='commun-button tab btn-gray' onClick={() => setDetailIsPage(null)} aria-label={translate('back')}>
                      <FontAwesomeIcon icon={faArrowLeft} />
                    </button>
                  )}
                </div>

                <div className='d-flex justify-content-center' style={{ width: "60%" }}>
                  <div className='d-flex justify-content-between align-items-center flex-wrap'>
                    {zipData && <>
                      <div className='col-sm-6 col-12'>
                        {(currentTab === AppTabs.USERS || currentTab === AppTabs.UPLOAD) &&
                          <input
                            type='text'
                            placeholder={translate('username')}
                            value={username}
                            onChange={handleUsernameChange}
                            className='form-control'
                            style={{ height: "90%", borderColor: username ? "" : "blue" }}
                          />
                        }
                      </div>

                      <div className='col-sm-5 col-12' >
                        {(currentTab === AppTabs.USERS || currentTab === AppTabs.UPLOAD) &&
                          <input
                            type='date'
                            value={selectedDate}
                            onChange={handleDateChange}
                            className='form-control'
                            style={{ height: "90%" }}
                          />}
                      </div>
                    </>}
                  </div>
                  <div className='d-flex justify-content-center p-1' >
                    {(([AppTabs.USERS, AppTabs.UPLOAD].includes(currentTab) && zipData) || (detailPage && elementsUpdated.length > 0)) && fileData &&
                      <button className={'commun-button ' + ((username && selectedDate) ? 'btn-black' : 'disabled-btn')} disabled={!(username && selectedDate)}
                        onClick={() => saveData(username, selectedDate, fileData)}>
                        <FontAwesomeIcon icon={faFloppyDisk} /></button>
                    }
                  </div>
                </div>
                <div className='d-flex justify-content-center' style={{ width: "20%" }}>
                  {(fileData && [AppTabs.USERS, AppTabs.UPLOAD].includes(currentTab)) && (
                    <button className='commun-button tab btn-gray' onClick={() => setShowDownloadModal(true)} aria-label={translate('downloadFiles')}>
                      <FontAwesomeIcon icon={faDownload} />
                    </button>
                  )}
                </div>
              </div>

              <div>
                <h3>{(currentTab === AppTabs.USERS && fileData) && (username + ' ' + selectedDate)}</h3>
              </div>
              {(currentTab === AppTabs.UPLOAD) ?
                <div>
                  <FileUploader onFileProcessed={handleFileProcessed} defaultUsername={''} defaultDate={new Date().toISOString().split('T')[0]} />
                </div>
                : (currentTab === AppTabs.USERS) ?
                  !fileData ?
                    <div className='d-flex justify-content-center ' >
                      <button className='commun-button btn-gray border-red' style={{ width: "90%" }} onClick={() => setCurrentTab(AppTabs.UPLOAD)}>
                        {translate("uploadToSeeData")} <span style={{ marginLeft: "15px" }}><FontAwesomeIcon icon={faDiamondTurnRight} color='gray' size='xl' /></span>
                      </button>
                      {backups.length > 0 &&
                        <button className='commun-button btn-gray border-red' style={{ width: "90%" }} onClick={() => setCurrentTab(AppTabs.HISTORY)}>
                          {translate("selectSavedData")}<span style={{ marginLeft: "15px" }}><FontAwesomeIcon icon={faDiamondTurnRight} color='gray' size='xl' /></span>
                        </button>
                      }
                    </div> :
                    !detailPage ? (
                      <div className="container-result-option d-flex justify-content-between flex-wrap">
                        <button className="commun-button display-btn btn-gray" style={{ width: "48%" }} onClick={() => handleButtonClick(PageName.IDONTFOLLOWBACK)} aria-label={translate('iDontFollowBack')}>
                          <span className='prev-number'>{fileData && fileData[PageName.IDONTFOLLOWBACK]?.length}</span>
                          <span className='prev-type' style={{ display: "block" }}>
                            {translate('iDontFollowBack')}
                          </span>
                        </button>
                        <button className="commun-button display-btn btn-gray" style={{ width: "48%" }} onClick={() => handleButtonClick(PageName.DONTFOLLOWMEBACK)} aria-label={translate('dontFollowMeBack')}>
                          <span className='prev-number'>{fileData && fileData[PageName.DONTFOLLOWMEBACK]?.length}</span>
                          <span className='prev-type' style={{ display: "block" }}>
                            {translate('dontFollowMeBack')}
                          </span>
                        </button>
                        <button className="commun-button display-btn btn-gray" style={{ width: "48%" }} onClick={() => handleButtonClick(PageName.FOLLOWERS)} aria-label={translate('followers')}>
                          <span className='prev-number'>{fileData && fileData[PageName.FOLLOWERS]?.length}</span>
                          <span className="prev-type" style={{ display: "block" }}>{translate('followers')}</span>
                        </button>
                        <button className="commun-button display-btn btn-gray" style={{ width: "48%" }} onClick={() => handleButtonClick(PageName.FOLLOWINGS)} aria-label={translate('followings')}>
                          <span className='prev-number'>{fileData && fileData[PageName.FOLLOWINGS]?.length}</span>
                          <span className='prev-type' style={{ display: "block" }}>
                            {translate('followings')}
                          </span>
                        </button>
                        <div className='' style={{ width: "100%" }}>{!previousExist && translate("notPreviousData")}</div>
                        <button className={"commun-button display-btn " + (previousExist ? 'btn-gray' : 'disabled-btn')} style={{ width: "48%" }} onClick={() => handleButtonClick(PageName.LOSTFOLLOWER)} aria-label={translate('lostFollower')} disabled={!previousExist}>
                          <span className='prev-number'>{fileData && fileData[PageName.LOSTFOLLOWER]?.length}</span>
                          <span className='prev-type' style={{ display: "block" }}>
                            {translate('lostFollower')}
                          </span>
                        </button>
                        <button className={"commun-button display-btn " + (previousExist ? 'btn-gray' : 'disabled-btn')} style={{ width: "48%" }} onClick={() => handleButtonClick(PageName.NEWFOLLOWERS)} aria-label={translate('newFollowers')} disabled={!previousExist}>
                          <span className='prev-number'>{fileData && fileData[PageName.NEWFOLLOWERS]?.length}</span>
                          <span className='prev-type' style={{ display: "block" }}>
                            {translate('newFollowers')}
                          </span>
                        </button>
                        <button className={"commun-button display-btn " + (previousExist ? 'btn-gray' : 'disabled-btn')} style={{ width: "48%" }} onClick={() => handleButtonClick(PageName.NEWFOLLOWINGS)} aria-label={translate('newFollowings')} disabled={!previousExist}>
                          <span className='prev-number'>{fileData && fileData[PageName.NEWFOLLOWINGS]?.length}</span>
                          <span className='prev-type' style={{ display: "block" }}>
                            {translate('newFollowings')}
                          </span>
                        </button>
                        <button className={"commun-button display-btn " + (previousExist ? 'btn-gray' : 'disabled-btn')} style={{ width: "48%" }} onClick={() => handleButtonClick(PageName.OLDFOLLOWING)} aria-label={translate('oldFollowings')} disabled={!previousExist}>
                          <span className='prev-number'>{fileData && fileData[PageName.OLDFOLLOWING]?.length}</span>
                          <span className='prev-type' style={{ display: "block" }}>
                            {translate('oldFollowings')}
                          </span>
                        </button>
                      </div>
                    ) : (
                      <div>
                        <div className='d-flex justify-content-center align-items-center'>
                          <button className='button2  ' onClick={() => setAcendingOrder(false)} aria-label={translate('ascending')}>
                            <FontAwesomeIcon icon={faArrowUp} />
                          </button>
                          <h2>{detailPage && translate(detailPage)}</h2>
                          <button className='button2  ' onClick={() => setAcendingOrder(true)} aria-label={translate('decreasing')}>
                            <FontAwesomeIcon icon={faArrowDown} />
                          </button>
                        </div>
                        <div className='user-list'>
                          {fileData &&
                            ascendingOrder !== null ?
                            fileData[detailPage]?.sort(compareLocked).map((data: Follower, index: number) => userRender(data, index, detailPage))
                            : fileData[detailPage]?.map((data: Follower, index: number) => userRender(data, index, detailPage))}
                        </div>
                      </div>
                    ) :
                  (currentTab === AppTabs.HISTORY) ?
                    <div>
                      <BackupList
                        backups={backups}
                        onSelectBackup={handleSelectBackup}
                        onDeleteBackup={handleDeleteBackup}
                      />
                    </div> :

                    (currentTab === AppTabs.STATS) &&
                    <div>

                      <h3>STATS{chartDataTimeline.length} </h3>
                      <FollowersTimelineChart data={chartDataTimeline} />
                      <FollowersChart data={chartData} />
                    </div>
              }

            </div>
          </section>

          <section className="container-desc">
            <h2>{translate('howToUse')}</h2>
            <ol>
              <li className='p-2'><strong>{translate('step1')}</strong> {translate('step1Details')}</li>
              <li className='p-2'><strong>{translate('step2')}</strong> {translate('step2Details')}</li>
              <li className='p-2'><strong>{translate('step3')}</strong> {translate('step3Details')}</li>
            </ol>
          </section>
        </main>

        <footer>
          <p>&copy; 2024 Unfollower - {translate('appTitle')}</p>
          <p>Contact - mail :  <a href="mailto:coolespace.com@gmail.com">coolespace.com@gmail.com</a></p>
        </footer>
      </div>
      <DownloadModal title={username + ' ' + selectedDate} show={showDownloadModal} onClose={() => setShowDownloadModal(false)} onDownload={handleDownload} />
    </div>
  );
}

export default App;

