// FollowersTimelineChart.tsx
import React from 'react';
import { useTranslation } from 'react-i18next';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

export interface ChartDataTimeLine {
  date: string;
  followers: number;
  followings: number;
  userInfos: string;
}

interface FollowersTimelineChartProps {
  data: ChartDataTimeLine[];
  interval?: 'month' | 'year';
}

const FollowersTimelineChart: React.FC<FollowersTimelineChartProps> = ({ data, interval }) => {

    const { t: translate } = useTranslation();

    const formatXAxis = (tickItem: string): string => {
        const date = new Date(tickItem);
        return interval === 'year' ? date.getFullYear().toString() : date.toLocaleString('default', { month: 'short', year: 'numeric' });
      };

      const customizedTooltip = (props:any) => {
        const { payload, label } = props;
        if (payload && payload.length) {
          return (
            <div className="custom-tooltip">
              <p className="label">{`${translate('date')}: ${label}`}</p>
              {payload.map((item:any, index:number) => (
                <p key={index} className="intro" style={{ color: item.color }}>
                  {`${translate(item.name)}: ${item.value}`}
                </p>
              ))}
            </div>
          );
        }
    
        return null;
      };

      
  return (
    <ResponsiveContainer width="100%" height={400}>
      <LineChart
        data={data}
        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        {/* <XAxis dataKey="date" tickFormatter={formatXAxis} /> */}
        <XAxis dataKey="date" />
        <YAxis />
        <Tooltip content={customizedTooltip} />
        <Legend formatter={(value) => translate(value)} />
        <Line type="monotone" dataKey="followers" stroke="#8884d8"   name={translate('followers')}/>

        <Line type="monotone" dataKey="userInfos" stroke="#ff7300" name={translate('Infos')}/>
        <Line type="monotone" dataKey="followings" stroke="#82ca9d" name={translate('followings')}/>

      </LineChart>
    </ResponsiveContainer>
  );
};

export default FollowersTimelineChart;

// FollowersTimelineChart.tsx
// import React from 'react';
// import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
// import { format, parseISO } from 'date-fns';

// export  interface ChartDataTimeLine {
//   date: string;
//   count: number;
//   users: string[]
// }

// interface FollowersTimelineChartProps {
//   data: ChartDataTimeLine[];
//   interval: 'month' | 'year';
// }

// const FollowersTimelineChart: React.FC<FollowersTimelineChartProps> = ({ data, interval }) => {
//   const formatXAxis = (tickItem: string) => {
//     const date = parseISO(tickItem);
//     return interval === 'year' ? format(date, 'yyyy') : format(date, 'MMM yyyy');
//   };

//   return (
//     <ResponsiveContainer width="100%" height={400}>
//       <LineChart
//         data={data}
//         margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
//       >
//         <CartesianGrid strokeDasharray="3 3" />
//         <XAxis dataKey="date" tickFormatter={formatXAxis} />
//         <YAxis />
//         <Tooltip />
//         <Legend />
//         <Line type="monotone" dataKey="count" stroke="#8884d8" />
//         <Line type="monotone" dataKey="users" stroke="#8884d8" />
//       </LineChart>
//     </ResponsiveContainer>
//   );
// };

// export default FollowersTimelineChart;