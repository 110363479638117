// FollowersChart.tsx
import React from 'react';
import { useTranslation } from 'react-i18next';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

export interface ChartData {
  date: string;
  followers: number;
  lostFollowers: number;
  newFollowers: number;
}

interface FollowersChartProps {
  data: ChartData[];
  interval?: 'month' | 'year';
}

const FollowersChart: React.FC<FollowersChartProps> = ({ data, interval }) => {
    const { t: translate } = useTranslation();

    const formatXAxis = (tickItem: string): string => {
        const date = new Date(tickItem);
        return interval === 'year' ? date.getFullYear().toString() : date.toLocaleString('default', { month: 'short', year: 'numeric' });
      };

      const customizedTooltip = (props:any) => {
        const { payload, label } = props;
        if (payload && payload.length) {
          return (
            <div className="custom-tooltip">
              <p className="label">{`${translate('date')}: ${label}`}</p>
              {payload.map((item:any, index:number) => (
                <p key={index} className="intro" style={{ color: item.color }}>
                  {`${translate(item.name)}: ${item.value}`}
                </p>
              ))}
            </div>
          );
        }
    
        return null;
      };

  return (
    <ResponsiveContainer width="100%" height={400}>
      <LineChart
        data={data}
        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" tickFormatter={formatXAxis} />
        <YAxis />
        <Tooltip content={customizedTooltip} />
        <Legend formatter={(value) => translate(value)} />
        <Line type="monotone" dataKey="followers" stroke="#8884d8" name={translate('followers')}/>
        <Line type="monotone" dataKey="lostFollowers" stroke="#ff7300" name={translate('lostFollower')}/>
        <Line type="monotone" dataKey="newFollowers" stroke="#82ca9d" name={translate('newFollowers')}/>
      </LineChart>
    </ResponsiveContainer>
  );
};

export default FollowersChart;
