// LanguageSwitcher.tsx
import React from 'react';
import { changeLanguage } from './i18n';

const LanguageSwitcher: React.FC = () => {
  const changeLanguageHandler = (language: string) => {
    changeLanguage(language);
  };

  return (
    <div className="language-switcher">
      <button className='commun-button btn-gray' onClick={() => changeLanguageHandler('en')}>En</button>
      <button className='commun-button btn-gray' onClick={() => changeLanguageHandler('fr')}>Fr</button>
    </div>
  );
};

export default LanguageSwitcher;
