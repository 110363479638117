export enum AppTabs { UPLOAD = "UPLOAD", USERS = "USERS", HISTORY = "HISTORY", STATS = "STATS" };



export enum PageName { FOLLOWERS = "followers", FOLLOWINGS = "followings", IDONTFOLLOWBACK = "iDontFollowBack", DONTFOLLOWMEBACK = "dontFollowMeBack", LOSTFOLLOWER = "lostFollower",
    NEWFOLLOWERS = "newFollowers", NEWFOLLOWINGS = "newFollings", OLDFOLLOWING = "oldFollowings" }

// I want to add a page to show Lost followers (people who have stopped following me)
// new followers, new followings, and people I've stopped following
// To do this, we can use the previous zip upload data if it exists
// and the current zip data download