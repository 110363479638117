// i18n.ts
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      "appTitle": "Instagram Followers Analysis",
      "appDescription": "Discover the followers you follow but who don't follow you back.",
      "howToUse": "How to use the application:",
      "step1": "Download your Instagram follower data:",
      "step1Details": "Go to your Instagram account and navigate to Your Activity > Download Your Information > Continue > Download or Transfer Information > Select Account > Some of Your Information > Select \"Followers and Followings\" in the Connections section > Download to Device > Date Range = All Time > Format = JSON > Create files",
      "step2": "Receive the ZIP file by email:",
      "step2Details": "Instagram will send you an email with a link to download your ZIP file.",
      "step3": "Import the ZIP file:",
      "step3Details": "Upload the downloaded ZIP file into our application above to start the analysis.",
      "followers": "Followers",
      "follower": "Abonné",
      "followings": "Followings",
      "iDontFollowBack": "I don't follow back",
      "dontFollowMeBack": "Don't follow me back",
      "lostFollower" : "Lost Follower",
      "newFollowers" : "New Followers",
      "newFollowings" : "New Followings",
      "oldFollowings" : "Old Followings",


      "selectDataToDownload": "Select Data to Download",
      "download": "Download",
      "back": "Back",
      "viewUsers": "View Users",
      "uploadData": "Upload Data",
      "downloadFiles": "Download Files",

      "uploadToSeeData" : "Upload To See Data",
      "selectSavedData" : "Select Saved Data",
      "dataNotFound" : "data Not Found",
      "ascending" : "Ascending",
      "decreasing" : "Decreasing",

    }
  },
  fr: {
    translation: {
      "appTitle": "Analyse des Abonnés Instagram",
      "appDescription": "Découvrez les abonnés que vous suivez mais qui ne vous suivent pas en retour.",
      "howToUse": "Comment utiliser l'application :",
      "step1": "Téléchargez vos données d'abonnés Instagram :",
      "step1Details": "Accédez à votre compte Instagram et allez dans Votre activité > Télécharger vos informations > Continuer > Télécharger ou transférer des informations > Sélectionner compte > Certaines de vos informations > Sélectionnez \"Followers et Followings\" dans la partie Connexions > Télécharger sur l'appareil > Plage de dates = Tout le temps > Format = JSON > Créez des fichiers",
      "step2": "Recevez le fichier ZIP par email :",
      "step2Details": "Instagram vous enverra un email avec un lien pour télécharger votre fichier ZIP.",
      "step3": "Importez le fichier ZIP :",
      "step3Details": "Importez le fichier ZIP téléchargé dans notre application ci-dessus pour commencer l'analyse.",
      "followers": "Abonnés",
      "follower": "Abonné",
      "followings": "Abonnements",
      "iDontFollowBack": "Je ne suis pas en retour",
      "dontFollowMeBack": "Ils ne me suivent pas en retour",
      "selectDataToDownload": "Sélectionnez les Données à Télécharger",
      "download": "Télécharger",
      "back": "Retour",
      "viewUsers": "Voir les Utilisateurs",
      "uploadData": "Télécharger des Données",
      "downloadFiles": "Télécharger des Fichiers",
      "uploadToSeeData" : "Télécharger pour voir les données",
      "selectSavedData" : "Sélectionner des données sauvegardées",

      "dataNotFound" : "données non trouvées",
      "ascending" : "Croissant",
      "decreasing" : "Décroissant",

      "lostFollower" : "Suiveur perdu",
      "newFollowers" : "Nouveaux Abonnés",
      "newFollowings" : "Nouveaux suivis",
      "oldFollowings" : "Anciens suivis",
    }
  }
};

export const changeLanguage = (language: string) => {
    i18n.changeLanguage(language);
  };
  
  // Récupérer la langue par défaut du navigateur
const browserLanguage = navigator.language.split('-')[0] || 'en';

  i18n
    .use(initReactI18next)
    .init({
      resources,
      lng: browserLanguage,
      fallbackLng: 'en',
      interpolation: {
        escapeValue: false
      }
    });
  
  export default i18n;
