// DownloadModal.tsx
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { faArrowLeft, faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface DownloadModalProps {
  title: string|undefined,
  show: boolean;
  onClose: () => void;
  onDownload: (selectedData: string[]) => void;
}

const DownloadModal: React.FC<DownloadModalProps> = ({ title, show, onClose, onDownload }) => {
  const { t: translate } = useTranslation();
  const [selectedData, setSelectedData] = useState<string[]>([]);

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.target;
    if (checked) {
      setSelectedData((prev) => [...prev, value]);
    } else {
      setSelectedData((prev) => prev.filter((data) => data !== value));
    }
  };

  const handleDownloadClick = () => {
    onDownload(selectedData);
    onClose();
  };

  if (!show) return null;

  return (
    <div className="modal">
      <div className="modal-content">
        <h2>{translate('selectDataToDownload')}</h2>
        <p>{title}</p>
        <div className='p-2 mt-1 ' >
          <label className='p-2' style={{ backgroundColor: "#e9ebee", display: "flex", cursor: "pointer" }}>
            <input className=''
              type="checkbox"
              value="followers"
              onChange={handleCheckboxChange}
            />
            <span style={{ width: "100%" }}>
              {translate('followers')}
            </span>
          </label>
        </div>
        <div className='p-2 mt-1 '>
          <label className='p-2' style={{ backgroundColor: "#e9ebee", display: "flex", cursor: "pointer" }}>
            <input
              type="checkbox"
              value="followings"
              onChange={handleCheckboxChange}
            />
            <span style={{ width: "100%" }}>
              {translate('followings')}
            </span>
          </label>
        </div>
        <div className='p-2 mt-1 '>
          <label className='p-2' style={{ backgroundColor: "#e9ebee", display: "flex", cursor: "pointer" }}>
            <input
              type="checkbox"
              value="iDontFollowBack"
              onChange={handleCheckboxChange}
            />
            <span style={{ width: "100%" }}>
              {translate('iDontFollowBack')}
            </span>
          </label>
        </div>
        <div className='p-2 mt-1 '>
          <label className='p-2' style={{ backgroundColor: "#e9ebee", display: "flex", cursor: "pointer" }}>
            <input
              type="checkbox"
              value="dontFollowMeBack"
              onChange={handleCheckboxChange}
            />
            <span style={{ width: "100%" }}>
              {translate('dontFollowMeBack')}
            </span>
          </label>
        </div>
        <div className='d-flex justify-content-between'>
          <button className='commun-button btn-gray' onClick={onClose}><FontAwesomeIcon icon={faArrowLeft} /></button>
          <button className='commun-button btn-green' onClick={handleDownloadClick}><FontAwesomeIcon icon={faDownload} /></button>
        </div>
      </div>
    </div>
  );
};

export default DownloadModal;
